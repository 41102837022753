<template>
  <div>
    <div class="loader loader--style2" title="Lütfen Bekleyiniz...">
      <svg width="100" height="100" viewBox="0 0 57 57" xmlns="http://www.w3.org/2000/svg" stroke="#17a2b8">
        <g fill="none" fill-rule="evenodd">
          <g transform="translate(1 1)" stroke-width="2">
            <circle cx="5" cy="50" r="5">
              <animate attributeName="cy"
                       begin="0s" dur="2.2s"
                       values="50;5;50;50"
                       calcMode="linear"
                       repeatCount="indefinite" />
              <animate attributeName="cx"
                       begin="0s" dur="2.2s"
                       values="5;27;49;5"
                       calcMode="linear"
                       repeatCount="indefinite" />
            </circle>
            <circle cx="27" cy="5" r="5">
              <animate attributeName="cy"
                       begin="0s" dur="2.2s"
                       from="5" to="5"
                       values="5;50;50;5"
                       calcMode="linear"
                       repeatCount="indefinite" />
              <animate attributeName="cx"
                       begin="0s" dur="2.2s"
                       from="27" to="27"
                       values="27;49;5;27"
                       calcMode="linear"
                       repeatCount="indefinite" />
            </circle>
            <circle cx="49" cy="50" r="5">
              <animate attributeName="cy"
                       begin="0s" dur="2.2s"
                       values="50;50;5;50"
                       calcMode="linear"
                       repeatCount="indefinite" />
              <animate attributeName="cx"
                       from="49" to="49"
                       begin="0s" dur="2.2s"
                       values="49;5;27;49"
                       calcMode="linear"
                       repeatCount="indefinite" />
            </circle>
          </g>
        </g>
      </svg>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-4 offset-md-4 myPage">
          <div class="myWrapper">
            <div class="row" style="margin-bottom: 25px; opacity: .9;">
              <div class="col-md-12">
                <h6>PAPARA ile para yatırma</h6>
              </div>
            </div>

            <form @submit="onSubmit" >
              <b-form-row>
                <div class="col-md-12">
                  <b-form-group>
                    <b-input  type="text"  placeholder="Adınız ve Soyadınız"  required v-model="name"/>
                    <p style="opacity:.7;"><small>— Adınızı ve Soyadınızı Papara hesabınızda olduğu gibi yazmalısınız...</small></p>
                  </b-form-group>
                </div>
                <div class="col-md-12">
                  <div class="input-group mb-3">
                    <input class="form-control" type="text" v-model="amount" placeholder="Miktar" min="1" required />
                    <div class="input-group-append" >
                      <span class="input-group-text" style="padding: 0.475rem 0.75rem"><small>TL</small></span>
                    </div>
                  </div>
                </div>
              </b-form-row>
              <button class="btn btn-info btn-block" type="submit" v-if="!loading" style="color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
">
                <span>İşleme Başla! <i class="fa fa-long-arrow-right m-l-7" aria-hidden="true"></i></span>
              </button>
              <button class="btn btn-info btn-block" disabled v-if="loading" style="color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
">
                <span><i class="fas fa-spinner fa-spin"></i></span>
              </button>

            </form>
          </div>
          <hr>
          <div class="row justify-content-between" style="opacity:.7;">
            <div class="col-md-6"><small>{{ $store.getters.siteName }}</small></div>
            <div class="col-md-6" style="text-align:right;"><small>© PaparaJest</small></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiService from "@/services/apiService";

export default {
  name: "One",
  data() {
    return {
      name: this.$store.getters.token.name,
      amount: '',
      loading: false,
      token: '',
      banks: [],
      bank_name: this.$store.getters.bank_name,
    }
  },
  props: ['sid'],
  methods: {
    checkPayment() {
      apiService.checkPayment({sid: this.sid, token: this.token})
          .then((res) => {
            if(res.state){
              if(res.data.payment_detail){
                if(res.data.payment_detail.status === '0'){
                  if(res.data.payment_detail.aninda_deposit){
                    window.location = res.data.payment_detail.aninda_link
                  }
                  this.$store.dispatch('paymentDetail', res.data.payment_detail)
                  this.$store.dispatch('getErr', {error: false, message: '', waiting: true})
                  this.$store.dispatch('getSteps', {
                    one: {
                      error: false,
                      now: false,
                      waiting: false,
                      approved: true
                    },
                    two: {
                      error: false,
                      now: false,
                      waiting: false,
                      approved: true
                    },
                    three: {
                      error: false,
                      now: true,
                      waiting: false,
                      approved: false
                    },
                  })
                  this.$socket.client.emit('payment', {token: this.token, sid: this.sid})
                }
              }
            }
          })

    },
    onSubmit(event) {
      event.preventDefault()
      this.loading = true
      apiService.getAccount({bank_name: this.bank_name, sid: this.sid, name: this.name, amount: this.amount, api_trx: this.$route.query.trx})
      .then((res) => {
        this.$store.dispatch('bankName', this.bank_name)
        this.loading = false
        if(res.state) {
          if(res.approved){
            this.$store.dispatch('getErr', {error: false, message: '', waiting: false})
            this.$store.dispatch('getSteps', {
              one: {
                error: false,
                now: false,
                waiting: false,
                approved: true
              },
              two: {
                error: false,
                now: false,
                waiting: false,
                approved: true
              },
              three: {
                error: false,
                now: false,
                waiting: false,
                approved: true
              },
            })
            this.$store.dispatch('getToken', {
              sid: this.sid,
              name: this.$store.getters.token.name,
              trx: '',
              paymentDetail: {
                token: '',
                payment: false,
                status: '',
              }
            })

          }else{
            this.$store.dispatch('getToken', {
              sid: this.$store.getters.token.sid,
              name: this.name,
              trx: this.$store.getters.token.sid,
              paymentDetail: {
                token: res.token,
                payment: true,
                status: '0'
              }
            })
            this.$store.dispatch('getSteps', {
              one: {
                error: false,
                now: false,
                waiting: false,
                approved: true
              },
              two: {
                error: false,
                now: false,
                waiting: true,
                approved: false
              },
              three: {
                error: false,
                now: false,
                waiting: true,
                approved: false
              },
            })
            this.$store.dispatch('getErr', {error: false, message: '', waiting: true})
            this.token = res.token
            this.checkPayment()
          }
        }
        else
          if(res.payment){
            this.$store.dispatch('getToken', {
              sid: this.$store.getters.token.sid,
              name: this.name,
              trx: this.$store.getters.token.sid,
              paymentDetail: {
                token: res.paymentData.token,
                payment: true,
                status: '0',
                bank_name: res.paymentData.bank_name
              }
            })
            this.$store.dispatch('getSteps', {
              one: {
                error: false,
                now: false,
                waiting: false,
                approved: true
              },
              two: {
                error: false,
                now: false,
                waiting: false,
                approved: true
              },
              three: {
                error: false,
                now: true,
                waiting: false,
                approved: false
              },
            })
            this.$store.dispatch('getErr', {error: false, message: '', waiting: true})
            this.token = res.paymentData.token
            this.checkPayment()

          }else{
            this.$store.dispatch('getConfirmMessage', {show: true, icon: 'error', title: 'Ops!', message: res.message})
          }
      })
    },
  },
}
</script>

<style scoped>
.btn-block {
  display: block;
  width: 100%;
}
.form-group {
  margin-bottom: 1rem;
}


</style>