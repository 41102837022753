<template>
  <div>
    <One :sid="sid"></One>
  </div>
</template>

<script>
import apiService from "@/services/apiService";
import One from "@/components/steps/One";

export default {
  name: "Home",
  data() {
    return {
      sid: this.$route.query.sid,
      trx: this.$route.query.trx,
      errorSid: this.$store.getters.errorSid,
      token: this.$store.getters.token,
      steps: this.$store.getters.steps,
      depositCheck: this.$store.getters.depositCheck,
      paymentDetail: {},
      api: false,
      copyIt: false,
      redirect: false,
      redirect_url: ''

    }
  },
  components: {  One },
  methods: {
    checkSid() {
      apiService.checkSid({sid: this.sid})
          .then((res) => {
            if(res.state){
              this.$store.dispatch('siteName', res.data.site_name)
              this.$store.dispatch('getErr', {error: false, message: '', waiting: false})
              this.$store.dispatch('getSteps', {
                one: {
                  error: false,
                  now: true,
                  waiting: false,
                  approved: false
                },
                two: {
                  error: false,
                  now: false,
                  waiting: true,
                  approved: false
                },
                three: {
                  error: false,
                  now: false,
                  waiting: true,
                  approved: false
                },
              })
              if(res.data.api){
                this.api = true
              }else{
                this.api = false
              }
              this.checkPayment()
            }else {
              this.$store.dispatch('getSteps', {
                one: {
                  error: true,
                  now: false,
                  waiting: false,
                  approved: false
                },
                two: {
                  error: true,
                  now: false,
                  waiting: false,
                  approved: false
                },
                three: {
                  error: true,
                  now: false,
                  waiting: false,
                  approved: false
                },
              })
              this.$store.dispatch('getErr', {error: true, message: res.message})
            }
          })
    },
    checkTrx() {
      apiService.checkTrx({sid: this.sid, api_trx: this.trx})
          .then((res) => {
            if(res.state){
              this.$store.dispatch('getErr', {error: false, message: '', waiting: false})
              this.$store.dispatch('getSteps', {
                one: {
                  error: false,
                  now: true,
                  waiting: false,
                  approved: false
                },
                two: {
                  error: false,
                  now: false,
                  waiting: true,
                  approved: false
                },
                three: {
                  error: false,
                  now: false,
                  waiting: true,
                  approved: false
                },
              })
              this.$store.dispatch('getTrx', this.trx)
            }else{
              this.$store.dispatch('getSteps', {
                one: {
                  error: true,
                  now: false,
                  waiting: false,
                  approved: false
                },
                two: {
                  error: true,
                  now: false,
                  waiting: false,
                  approved: false
                },
                three: {
                  error: true,
                  now: false,
                  waiting: false,
                  approved: false
                },
              })
              this.$store.dispatch('getErr', {error: true, message: 'Bu parametre daha önce kullanılmış, lütfen site ekranından tekrar giriş sağlayın'})
            }
          })
    },
    checkPayment() {
      if(this.token.paymentDetail.payment){
        apiService.checkPayment({sid: this.sid, token: this.token.paymentDetail.token})
            .then((res) => {
              if(res.state){
                if(res.data.payment_detail){
                  if(res.data.payment_detail.status === '0'){
                    if(res.data.payment_detail.aninda_deposit){
                      window.location = res.data.payment_detail.aninda_link
                    }
                    this.$store.dispatch('paymentDetail', res.data.payment_detail)
                    this.$store.dispatch('getErr', {error: false, message: '', waiting: true})
                    this.$store.dispatch('getSteps', {
                      one: {
                        error: false,
                        now: false,
                        waiting: false,
                        approved: true
                      },
                      two: {
                        error: false,
                        now: false,
                        waiting: false,
                        approved: true
                      },
                      three: {
                        error: false,
                        now: true,
                        waiting: false,
                        approved: false
                      },
                    })
                    this.$socket.client.emit('payment', {token: this.token.paymentDetail.token, sid: this.sid})

                  }else if(res.data.payment_detail.status === '1'){
                    this.$store.dispatch('getErr', {error: false, message: '', waiting: false})
                    this.$store.dispatch('getSteps', {
                      one: {
                        error: false,
                        now: false,
                        waiting: false,
                        approved: true
                      },
                      two: {
                        error: false,
                        now: false,
                        waiting: false,
                        approved: true
                      },
                      three: {
                        error: false,
                        now: false,
                        waiting: false,
                        approved: true
                      },
                    })
                    this.$store.dispatch('getToken', {
                      sid: this.sid,
                      name: this.$store.getters.token.name,
                      trx: '',
                      paymentDetail: {
                        token: '',
                        payment: false,
                        status: '',
                      }
                    })
                  }else if(res.data.payment_detail.status === '2'){
                    this.$store.dispatch('getErr', {error: true, message: 'Yatırım talebiniz red edildi. Lütfen site ekranından giriş sağlayın ve bilgilerinizi doğru girerek tekrar deneyin.', waiting: false})
                    this.$store.dispatch('getSteps', {
                      one: {
                        error: true,
                        now: false,
                        waiting: false,
                        approved: false
                      },
                      two: {
                        error: true,
                        now: false,
                        waiting: false,
                        approved: false
                      },
                      three: {
                        error: true,
                        now: false,
                        waiting: false,
                        approved: false
                      },
                    })
                    this.$store.dispatch('getToken', {
                      sid: this.sid,
                      name: this.$store.getters.token.name,
                      trx: '',
                      paymentDetail: {
                        token: '',
                        payment: false,
                        status: '',
                      }
                    })
                  }
                }
              }else{
                this.$store.dispatch('getErr', {error: false, message: '', waiting: false})
                this.$store.dispatch('getSteps', {
                  one: {
                    error: false,
                    now: true,
                    waiting: false,
                    approved: false
                  },
                  two: {
                    error: false,
                    now: false,
                    waiting: true,
                    approved: false
                  },
                  three: {
                    error: false,
                    now: false,
                    waiting: true,
                    approved: false
                  },
                })
              }
            })
      }else{
        if(this.api){
          this.checkTrx()
        }else{
          this.$store.dispatch('getErr', {error: false, message: '', waiting: false})
          this.$store.dispatch('getSteps', {
            one: {
              error: false,
              now: true,
              waiting: false,
              approved: false
            },
            two: {
              error: false,
              now: false,
              waiting: true,
              approved: false
            },
            three: {
              error: false,
              now: false,
              waiting: true,
              approved: false
            },
          })
        }
      }

    },
    sendPaymentByUser(){
      apiService.sendPaymentByUser({sid: this.sid, token: this.token.paymentDetail.token})
      .then(() => {

      })
    },
    showAccountDetail(){
      this.$swal({
        allowOutsideClick: false,
        showConfirmButton: false,
        icon: 'info',
        title: 'Hesap Detayları',
        html: '<div class="notif-alert warning mtop mbottom"><b >Uyarı</b>:  Lütfen aşağıda belirtilen Papara hesabına <strong>'+this.currency(this.$store.getters.paymentDetail.amount)+' TL</strong> gönderim sağlayınız.</div>\n' +
            '                          <table class="table table-bordered table-responsive">\n' +
            '                              <thead>\n' +
            '                                <tr>\n' +
            '                                  <th scope="col">Hesap Adı </th>\n'+
            '                                  <th scope="col" class="text-success text-lg-center" id="paymentDetail_account_name">'+this.$store.getters.paymentDetail.account_name+' <i class="fas fa-copy"></i></th>\n' +
            '                                </tr>\n' +
            '                                <tr>\n' +
            '                                  <th scope="col">Papara No </th>\n'+
            '                                  <th scope="col" class="text-success text-lg-center" id="paymentDetail_account_number">'+this.$store.getters.paymentDetail.account_number+'<i class="fas fa-copy"></i></th>\n' +
            '                                </tr>\n' +
            '                                <tr>\n' +
            '                                  <th scope="col">Tutar </th>\n'+
            '                                  <th scope="col" class="text-lg-center">'+this.currency(this.$store.getters.paymentDetail.amount)+' TL</th>\n' +
            '                                </tr>\n' +
            '                              </thead>\n' +
            '                          </table>\n' +
            ' <div class="copyarea hidden"><div class="notif-alert success mtop mbottom "> Kopyalandı! </div></div> \n' +
            '<button class="btn btn-success" id="checkingButton"> <i class="fas fa-check-circle"></i> Yatırımı Tamamladım </button>',
      });
      const copy = (value) => {
        if(value === 'name')
          this.copy(this.$store.getters.paymentDetail.name)
        if(value === 'bank_Name')
          this.copy(this.$store.getters.paymentDetail.bank_name)
        if(value === 'account_name')
          this.copy(this.$store.getters.paymentDetail.account_name)
        if(value === 'account_number')
          this.copy(this.$store.getters.paymentDetail.account_number)
      }
      const checkingButton = () => {
        this.checking()
        this.sendPaymentByUser()
      }
      document.getElementById('paymentDetail_account_name').onclick = function() {
        copy('account_name')
      }
      document.getElementById('paymentDetail_account_number').onclick = function() {
        copy('account_number')
      }
      document.getElementById('checkingButton').onclick = function() {
        checkingButton()
      }
      const items = document.getElementsByClassName('fastarea')
      if(this.$store.getters.paymentDetail.fast_account){
        items[0].classList.remove('hidden');
      }else{
        items[0].classList.add('hidden');
      }
    },
    currency(value){
      return parseFloat(value).toLocaleString(undefined, {minimumFractionDigits : 2})
    },
    copy(val){
      this.copyIt = true
      const items = document.getElementsByClassName('copyarea')
      items[0].classList.remove('hidden');
      setTimeout(() => {
        items[0].classList.add('hidden');
      }, 2000)
      let input = document.createElement('textarea');
      input.innerHTML = val;
      document.body.appendChild(input);
      input.select();
      let result = document.execCommand('copy');
      document.body.removeChild(input);
      return result
    },
    error(){
      this.$swal({
        allowOutsideClick: false,
        showConfirmButton: false,
        icon: 'error',
        title: 'Hata',
        html: '<div class="notif-alert warning mtop mbottom">'+this.errorSid.message+'</div>\n',
      });

    },
    approved(){
      this.$swal({
        allowOutsideClick: false,
        showConfirmButton: false,
        icon: 'success',
        title: 'Tebrikler!',
        html: '<div class="notif-alert success mtop mbottom"> Yatırımınız başarılı. İlginiz için teşekkürler </div>\n',
      });

    },
    checking(){
      this.$swal({
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          this.$swal.showLoading()
        },
        icon: 'info',
        title: 'İnceleniyor..',
        html: '<div class="notif-alert info mtop mbottom"> <i class="fas fa-spinner fa-spin spinne"></i> Yatırımınız şuanda inceleniyor kısa süre içerisinde sonuçlandırılacaktır.</div>\n'
      });

    },
    getSiteDetail(){
      apiService.siteDetail({sid: this.sid})
          .then((res) => {
            if(res.state){
              this.redirect = res.data.redirect
              this.redirect_url = res.data.redirect_url
            }
          })
    },

  },
  created() {
    this.checkSid()
    this.getSiteDetail()
  },
  watch: {
    trx: function () {
      this.$store.dispatch('getTrx', this.trx)
    },
    '$store.getters.errorSid': function() {
      this.errorSid = this.$store.getters.errorSid
    },
    '$store.getters.token': function() {
      this.token = this.$store.getters.token
    },
    '$store.getters.steps': function() {
      this.steps = this.$store.getters.steps
    },
    '$store.getters.depositCheck': function() {
      this.depositCheck = this.$store.getters.depositCheck
      if(this.depositCheck.state && this.depositCheck.token === this.token.paymentDetail.token){
        this.checkPayment()
      }
    },
    /*
    !errorSid.error && errorSid.waiting && !steps.three.approved
     */
    errorSid: function () {
      if(!this.errorSid.error && this.errorSid.waiting && !this.steps.three.approved){
        if(this.$store.getters.paymentDetail.sended){
          this.checking()
        }else{
          this.showAccountDetail()
        }
      }
      if(this.errorSid.error){
        this.error()
      }
    },
    steps: function () {
      if(this.steps.three.approved){
        this.approved()
      }
    },
  },
}
</script>

<style>
.transaction-status-content .content .status, .transaction-status-content .content .status span {
  width: 100%;
  display: block;
  float: left;
  text-align: center;
}
.transaction-status-content .content .status.success .icon {
  background-color: #67ae23;
  color: #fff;
  font-size: 64px;
  -webkit-animation: flip 1s;
  animation: flip 1s;
}
.transaction-status-content .content .status.error .icon {
  background-color: #dc3545;
  color: #fff;
  font-size: 64px;
  -webkit-animation: flip 1s;
  animation: flip 1s;
}
.transaction-status-content .content .status.error .warning {
  background-color: #ffc107;
  color: #fff;
  font-size: 64px;
  -webkit-animation: flip 1s;
  animation: flip 1s;
}
.transaction-status-content .content .status.error .info {
  background-color: #0d6efd;
  color: #fff;
  font-size: 64px;
  -webkit-animation: flip 1s;
  animation: flip 1s;
}
.transaction-status-content .content .status.success span.head {
  -webkit-animation: fadeIn 2s;
  animation: fadeIn 2s;
}
.transaction-status-content .content .status.success span.title {
  -webkit-animation: fadeIn 2.5s;
  animation: fadeIn 2.5s;
}

.transaction-status-content .content .status span.title {
  font-size: 14px;
  font-weight: 300;
  color: #71777c;
}
.transaction-status-content .content .status, .transaction-status-content .content .status span {
  width: 100%;
  display: block;
  float: left;
  text-align: center;
}
.hidden {
  display: none;
}
</style>